const items = [
  {
    id: 1,
    nameUrl: 'living',
    name: '居家清潔',
  },
  {
    id: 2,
    nameUrl: 'bug',
    name: '防蚊防蟲',
  },

  {
    id: 3,
    nameUrl: 'electrical',
    name: '電器製品',
  },

  {
    id: 4,
    nameUrl: 'miscellaneous',
    name: '雜貨日用品',
  },
];

export default items;
