import Vue from 'vue'
import NuxtLoading from './components/nuxt-loading.vue'

import '..\\src\\styles\\_index.css'

import _77180f1e from '..\\src\\layouts\\blank.vue'
import _6f6c098b from '..\\src\\layouts\\default.vue'
import _6f2dc326 from '..\\src\\layouts\\two-column.vue'

const layouts = { "_blank": _77180f1e,"_default": _6f6c098b,"_two-column": _6f2dc326 }

export default {
  head: {"titleTemplate":function(titleChunk) {
      return titleChunk
        ? // ? `${titleChunk} - ${process.env.CANONICAL_TITLE}`
          `${titleChunk} | ${process.env.CANONICAL_TITLE}`
        : process.env.CANONICAL_TITLE;
    },"htmlAttrs":{"prefix":"og: http:\u002F\u002Fogp.me\u002Fns# fb: http:\u002F\u002Fogp.me\u002Fns\u002Ffb#"},"meta":[{"property":"fb:app_id","content":"192874377741374"},{"property":"fb:pages","content":"436843193180529"},{"hid":"og:type","property":"og:type","content":"website"},{"hid":"og:title","property":"og:title","content":"Unipapa｜台灣簡約設計品牌，讓生活更便利"},{"hid":"og:image","property":"og:image","content":"https:\u002F\u002Fd1jjjfa1mlyr2v.cloudfront.net\u002Fc\u002Fassets\u002F38260567_844825345715643_2903868620511641600_o.jpg"},{"hid":"og:description","name":"description","property":"og:description","content":"外型簡約舒適、功能實用貼心是 Unipapa 一貫的設計堅持，『讓設計全面走向民生』則是 Unipapa 期望達到的品牌願景，我們希望有朝一日，Unipapa 能成為每個人生活中，不可或缺的存在。"},{"hid":"viewport","name":"viewport","content":"width=device-width, initial-scale=1, shrink-to-fit=no"},{"property":"og:site_name","content":"Unipapa｜台灣簡約設計品牌，讓生活更便利"},{"property":"og:locale","content":"zh_TW"},{"property":"og:rich_attachment","content":"true"}],"link":[{"rel":"stylesheet","href":"https:\u002F\u002Ffonts.googleapis.com\u002Fcss2?family=Noto+Sans+TC:wght@100;300;400;500;700;900&family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap"}],"style":[],"script":[]},

  render(h, props) {
    const loadingEl = h('nuxt-loading', { ref: 'loading' })
    const layoutEl = h(this.layout || 'nuxt')
    const templateEl = h('div', {
      domProps: {
        id: '__layout'
      },
      key: this.layoutName
    }, [ layoutEl ])

    const transitionEl = h('transition', {
      props: {
        name: 'layout',
        mode: 'out-in'
      },
      on: {
        beforeEnter(el) {
          // Ensure to trigger scroll event after calling scrollBehavior
          window.$nuxt.$nextTick(() => {
            window.$nuxt.$emit('triggerScroll')
          })
        }
      }
    }, [ templateEl ])

    return h('div', {
      domProps: {
        id: '__nuxt'
      }
    }, [
      loadingEl,
      transitionEl
    ])
  },
  data: () => ({
    layout: null,
    layoutName: ''
  }),
  beforeCreate() {
    Vue.util.defineReactive(this, 'nuxt', this.$options.nuxt)
  },
  created() {
    // Add this.$nuxt in child instances
    Vue.prototype.$nuxt = this
    // add to window so we can listen when ready
    if (typeof window !== 'undefined') {
      window.$nuxt = this
    }
    // Add $nuxt.error()
    this.error = this.nuxt.error
  },

  mounted() {
    this.$loading = this.$refs.loading
  },
  watch: {
    'nuxt.err': 'errorChanged'
  },

  methods: {
    errorChanged() {
      if (this.nuxt.err && this.$loading) {
        if (this.$loading.fail) this.$loading.fail()
        if (this.$loading.finish) this.$loading.finish()
      }
    },

    setLayout(layout) {
      if (!layout || !layouts['_' + layout]) {
        layout = 'default'
      }
      this.layoutName = layout
      this.layout = layouts['_' + layout]
      return this.layout
    },
    loadLayout(layout) {
      if (!layout || !layouts['_' + layout]) {
        layout = 'default'
      }
      return Promise.resolve(layouts['_' + layout])
    }
  },
  components: {
    NuxtLoading
  }
}
