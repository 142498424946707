<template>
  <div>
    <TheHeaderMd
      :class="headerPosition"
      class="z-2"
    />
    <TheHeader
      :class="headerPosition"
      class="z-2"
    />

    <div
      :class="{
        [$style['is-push-down']]: !$route.meta.isPullUp,
      }"
      class="min-h-screen flex flex-col relative z-1"
    >
      <div class="flex-auto flex flex-col">
        <transition name="bounce">
          <nuxt v-if="isReady" />
        </transition>
      </div>

      <!-- footer spacing -->
      <div
        v-if="!$route.meta.isFooterSpacing && $route.meta.isFooterSpacing !== false"
        class="flex-none h-20 lg:h-20"
      />

      <TheFooter class="flex-none" />
    </div>

    <!--
    .##........#######...######...####.##....##....##.....##..#######..########.....###....##......
    .##.......##.....##.##....##...##..###...##....###...###.##.....##.##.....##...##.##...##......
    .##.......##.....##.##.........##..####..##....####.####.##.....##.##.....##..##...##..##......
    .##.......##.....##.##...####..##..##.##.##....##.###.##.##.....##.##.....##.##.....##.##......
    .##.......##.....##.##....##...##..##..####....##.....##.##.....##.##.....##.#########.##......
    .##.......##.....##.##....##...##..##...###....##.....##.##.....##.##.....##.##.....##.##......
    .########..#######...######...####.##....##....##.....##..#######..########..##.....##.########
    -->
    <div
      class="pin-x pin-y fixed w-screen h-screen modal-login  "
      :class=" { 'active': $store.state.site.isShowLoginModal }"
      @click="$store.commit('site/showLoginModal', false)"
    >
      <div
        class="absolute bg-white px-3 py-8 modal-login-frame rounded-10"
        @click.stop.prevent
      >
        <div class="text-center text-16 font-bold mb-5">
          今天就加入 Unipapa，支持我們。
        </div>
        <div class="text-center text-white ">
          <nuxt-link
            class="btn bg-blue px-8 text-14 py-3 rounded-20"
            to="/login"
            @click.native="$store.commit('site/showLoginModal', false)"
          >
            註冊／登入會員
          </nuxt-link>
        </div>
      </div>
    </div>

    <!--
    .########..########...#######..########.####.##.......########....##.....##..#######..########.....###....##......
    .##.....##.##.....##.##.....##.##........##..##.......##..........###...###.##.....##.##.....##...##.##...##......
    .##.....##.##.....##.##.....##.##........##..##.......##..........####.####.##.....##.##.....##..##...##..##......
    .########..########..##.....##.######....##..##.......######......##.###.##.##.....##.##.....##.##.....##.##......
    .##........##...##...##.....##.##........##..##.......##..........##.....##.##.....##.##.....##.#########.##......
    .##........##....##..##.....##.##........##..##.......##..........##.....##.##.....##.##.....##.##.....##.##......
    .##........##.....##..#######..##.......####.########.########....##.....##..#######..########..##.....##.########
    -->
    <div
      class="pin-x pin-y fixed w-screen h-screen modal-login"
      :class=" { 'active': $store.state.site.isShowProfileModal }"
      @click="$store.commit('site/showProfileModal', false)"
    >
      <div
        class="absolute bg-white px-6 py-8 modal-login-frame rounded-10 "
        @click.stop.prevent
      >
        <div
          v-if="$store.state.user.user"
          class="flex"
        >
          <div class="mr-5">
            <vue-avatar
              v-if="$store.state.user.user.avatar"
              :username="$store.state.user.user.name"
              :size="64"
              :src="$store.state.user.user.avatar | assetURL"
              class="mx-auto"
            />
            <vue-avatar
              v-else
              :username="$store.state.user.user.name"
              :size="64"
              background-color="#000000"
              color="#FFFFFF"
              class="mx-auto"
            />
          </div>
          <div class="flex-grow">
            <div class="text-16">
              {{ $store.state.user.user.name }}
            </div>

            <div
              class="w-full h-6 line-champ-1"
              style="-webkit-box-orient: vertical; max-width:165px;"
            >
              {{ $store.state.user.user.email }}
            </div>
          </div>
        </div>
        <hr>
        <div>
          <nuxt-link
            class="btn border rounded-20 w-full mb-3 border-eee"
            to="/user"
            @click.native="$store.commit('site/showProfileModal', false)"
          >
            帳號設定
          </nuxt-link>
          
          <nuxt-link
            class="btn border rounded-20 w-full mb-3 border-eee"
            to="/orders"
            @click.native="$store.commit('site/showProfileModal', false)"
          >
            訂單紀錄
          </nuxt-link>

          <nuxt-link
            class="btn border rounded-20 w-full mb-8 border-eee"
            to="/user-order-subscribe/listing"
            @click.native="$store.commit('site/showProfileModal', false)"
          >
            <!-- <span
              class="material-icons mr-2 text-12"
            >
              settings
            </span> -->
            訂閱控制台
          </nuxt-link> 
          
        
          <button
            class="btn border rounded-20 text-blue w-full border-eee"
            @click="logoutDo()"
          >
            登出
          </button>
        </div>
      </div>
    </div>

    <!--
    .########..########...#######..########.####.##.......########....##.....##..#######..########.....###....##......
    .##.....##.##.....##.##.....##.##........##..##.......##..........###...###.##.....##.##.....##...##.##...##......
    .##.....##.##.....##.##.....##.##........##..##.......##..........####.####.##.....##.##.....##..##...##..##......
    .########..########..##.....##.######....##..##.......######......##.###.##.##.....##.##.....##.##.....##.##......
    .##........##...##...##.....##.##........##..##.......##..........##.....##.##.....##.##.....##.#########.##......
    .##........##....##..##.....##.##........##..##.......##..........##.....##.##.....##.##.....##.##.....##.##......
    .##........##.....##..#######..##.......####.########.########....##.....##..#######..########..##.....##.########
    -->
    <div
      class="pin-x pin-y fixed w-screen h-screen modal-login "
      :class=" { 'active': $store.state.site.isShowSearch }"
      @click="$store.commit('site/showSearchModal', false)"
    >
      <div class="container py-20 lg:py-20 hidden lg:block">
        <div
          class="bg-white px-0 py-0 rounded-10 border-yada  bg-f9f9f9 overflow-hidden"
          style="box-shadow: 0 0 10px rgba(0, 0, 0, 0.1)"
          @click.stop.prevent
        >
          <form
            aria-label="商品"
            role="search"
            class="flex bg-white py-4"
            @submit.prevent="onSearchBarSubmit"
            @keyup.esc.prevent="clearSearchBar"
          >
            <button
              aria-label="搜尋"
              title="進行搜尋"
              type="submit"
              class="flex-none px-4"
            >
              <div class="icon-unipapa icon-search text-26" />
            </button>
            <input
              id="search2"
              ref="searchBar"
              v-model="searchValue"
              type="search"
              placeholder="輸入關鍵字搜尋 Unipapa.com"
              aria-label="搜尋商品"
              class="flex-auto appearance-none leading-none focus:outline-none px-5 rounded-none bg-none"
              required
              @focus="onSearchBarFocus"
              @blur="onSearchBarBlur"
              @keyup.enter="onSearchBarSubmit"
            >
            <button
              type="button"
              aria-label="關閉"
              title="取消搜尋"
              class="flex-none px-4"
              @click="clearSearchBar"
            >
              <div class="icon-unipapa icon-cross text-26" />
            </button>
          </form>
          <div class="py-5 px-5">
            <div class="text-12 text-999 mb-3">
              或許您會想找：
            </div>
            <div class="flex flex-wrap -mx-3">
              <!-- xxxxxxxxxxxxxx -->
              <nuxt-link
                class="w-1/5 px-3"
                to="/article/listing"
              >
                <div class="flex rounded-5 bg-white items-center px-3 py-3 border-yada haohao">
                  <div class="flex-auto text-center">
                    文章特集
                  </div>
                </div>
              </nuxt-link>

              <!-- xxxxxxxxxxxxxx -->
              <nuxt-link
                class="w-1/5 px-3"
                to="/shop/index"
              >
                <div class="flex rounded-5 bg-white items-center px-3 py-3 border-yada haohao">
                  <div class="flex-auto text-center">
                    實體販售地點
                  </div>
                </div>
              </nuxt-link>

              <!-- xxxxxxxxxxxxxx -->
              <!-- v-if="isDev" -->
              <nuxt-link
                class="w-1/5 px-3"
                to="/order-subscribe/index"
              >
                <div class="flex rounded-5 bg-white items-center px-3 py-3 border-yada haohao">
                  <div class="flex-auto text-center">
                    叮咚！訂閱配送
                  </div>
                </div>
              </nuxt-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--
    ....##......##......##......##......########.##.....##.########.##....##.########
    ..####....####....####....####......##.......##.....##.##.......###...##....##...
    ....##......##......##......##......##.......##.....##.##.......####..##....##...
    ....##......##......##......##......######...##.....##.######...##.##.##....##...
    ....##......##......##......##......##........##...##..##.......##..####....##...
    ....##......##......##......##......##.........##.##...##.......##...###....##...
    ..######..######..######..######....########....###....########.##....##....##...
    -->
    <div
      v-if="$store.state.site.isShow1111"
      class="fixed h-screen w-screen lg:hidden"
      style="background:rgba(0,0,0,0.5); left:0; top:0; z-index:100"
    />

    <div
      v-if="$store.state.site.isShow1111"
      class="fixed bg-white px-4 py-4 modal-notification-frame border-yada rounded-10 shadow"
    >
      <!-- <div class="flex items-center">
        <div class="mr-3 font-bold text-16">
          「雙俗壹柑仔店」優惠活動倒數！！
        </div>
        <div class="ml-auto">
          <button
            class="border-yada text-blue px-2 py-1 rounded-5"
            @click="isShow1111 = false"
          >
            關閉
          </button>
        </div>
      </div>
      <hr class="my-3"> -->
    
      <!-- <hr class="my-3"> -->
      <div class="text-blue text-16 text-center">
        <!-- 101日 23時 53分 23秒 34.60 -->
        <!-- {{ countdown.day | pad(2) }}日 {{ countdown.hour | pad(2) }}時 {{ countdown.minute | pad(2) }}分 {{ countdown.second | pad(2) }}秒 {{ countdown.milesecond | pad(3) }} -->
        <!-- {{ countdown.day | pad(2) }}日 {{ countdown.hour | pad(2) }}時 {{ countdown.minute | pad(2) }}分 {{ countdown.second | pad(2) }}秒 -->
        雙俗壹柑仔店優惠進行中！
      </div>

      <div class="text-center my-2 text-999">
        (11月11日23時59分為止)
      </div>
      <hr class="my-3">
      <!-- <a
        class="bg-blue text-white py-2 rounded-5 w-full text-16 block text-center"
        href="https://calendar.google.com/calendar/u/0/r/eventedit?trp=true&sf=true&output=xml&dates=20201105T040000Z/20201111T155900Z&details=Unipapa%E9%9B%99%E4%BF%97%E5%A3%B9%E6%9F%91%E4%BB%94%E5%BA%97%E5%85%A8%E5%B9%B4%E6%9C%80%E4%BF%97%E5%8F%AA%E5%9C%A811/5-11/11%3Cbr%3E%E9%80%B2%E5%85%A5%3Ca+href%3D%22https://www.unipapa.com%22%3EUnipapa%E5%AE%98%E7%B6%B2%3C/a%3E&location=%E9%9B%99%E4%BF%97%E5%A3%B9%E6%9F%91%E4%BB%94%E5%BA%97&text=Unipapa%E9%9B%99%E4%BF%97%E5%A3%B9%E6%9F%91%E4%BB%94%E5%BA%97%E9%96%8B%E8%B3%A3&fbclid=IwAR1SRwOiU7p7A6SwXERJvp73fWpPg3h7It_liO5ClUFBEWuhw9ptPSYYU9o"
        target="_blank"
        @click="isShow1111 = false"
      >
        Google 行事曆提醒
      </a> -->
      <nuxt-link
        class="bg-blue text-white py-2 rounded-5 w-full text-14 block text-center"
        to="/page/item/Shuangsuyiganzai-shop"
      >
        前往搶購
      </nuxt-link>
    </div>

    <!--
    .##....##..#######..########.####.########.####..######.....###....########.####..#######..##....##
    .###...##.##.....##....##.....##..##........##..##....##...##.##......##.....##..##.....##.###...##
    .####..##.##.....##....##.....##..##........##..##........##...##.....##.....##..##.....##.####..##
    .##.##.##.##.....##....##.....##..######....##..##.......##.....##....##.....##..##.....##.##.##.##
    .##..####.##.....##....##.....##..##........##..##.......#########....##.....##..##.....##.##..####
    .##...###.##.....##....##.....##..##........##..##....##.##.....##....##.....##..##.....##.##...###
    .##....##..#######.....##....####.##.......####..######..##.....##....##....####..#######..##....##
    -->
    <!-- v-if="$store.state.site.notification.length > 0" -->
    <div
      v-if="$store.state.site.notification.length > 0"
      class="fixed modal-notification-frame "
    >
      <notification />
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie';
import TheHeaderMd from '../components/TheHeaderMd.vue';
import TheHeader from '../components/TheHeader.vue';
import TheFooter from '../components/TheFooter.vue';
import notification from '../components/notification.vue';

export default {
  components: {
    TheHeaderMd,
    TheHeader,
    TheFooter,
    notification,
  },

  data() {
    return {
      // isReady: false,
      isReady: true,
      // search
      searchValue: '',
      isSearchBarOpen: false,
      // 1111
      // isShow1111: true,
      countdown: {
        day: 3,
        hour: 13,
        minute: 23,
        second: 59,
        milesecond: '00.0',
      },
      isCountdownReady: false,
      countdownTime: 0,
    };
  },

  computed: {
    headerPosition() {
      return (this.$route.meta.isHeaderFixed === undefined
      ? true
      : this.$route.meta.isHeaderFixed)
        ? 'fixed'
        : 'absolute';
    },
  },

  created() {
    this.initCart();
  },

  async mounted() {
    const self = this;

    if (this.$auth.getToken() && !window.isCheckedLoginStatus) {
      try {
        const result = await this.post(`auth/token`, {}).catch(() => {
          // remove cookie token
          self.$auth.logout();
        });
        window.isCheckedLoginStatus = true;

        const data = result.data;

        this.$store.commit('user/setUser', {
          user: data.user,
          coupons: data.coupons,
          isNewUser: data.isNewUser,
        });
      } catch (e) {
        // yada
      }
    }

    // this.isReady = true;

    this.initDatalayer();

    // this.countdownTime = new Date('2020-11-04T23:59:59+08:00').valueOf();
    this.countdownTime = new Date('2020-11-05T12:00:00+08:00').valueOf();
    // this.startTimer();
    // this.startTimerMilesecond();

    if (this.isDev) {
      // this.isShow1111 = false;
    } else {
      if (
        new Date('2020-11-05T12:00:00+08:00').valueOf() < new Date().valueOf()
      ) {
        // this.isShow1111 = false;
      }
    }

    // check is invalid, remove cookie token
    if (this.$store.state.site.isInvalidToken) {
      try {
        Cookies.remove('token');
        this.$store.commit('site/setInvalid', false);
      } catch (e) {
        // yada
      }
    }
  },

  methods: {
    // ....##......##......##......##......########.##.....##.########.##....##.########
    // ..####....####....####....####......##.......##.....##.##.......###...##....##...
    // ....##......##......##......##......##.......##.....##.##.......####..##....##...
    // ....##......##......##......##......######...##.....##.######...##.##.##....##...
    // ....##......##......##......##......##........##...##..##.......##..####....##...
    // ....##......##......##......##......##.........##.##...##.......##...###....##...
    // ..######..######..######..######....########....###....########.##....##....##...
    startTimerMilesecond() {
      let milesecond = 1000 - new Date().getMilliseconds();
      this.countdown.milesecond = milesecond;
      setTimeout(this.startTimerMilesecond, 20);
    },

    startTimer() {
      const now = new Date().valueOf();

      if (this.countdown.second <= 0 || this.isCountdownReady == false) {
        let differ = this.countdownTime - now;

        const day = Number.parseInt(differ / (60 * 60 * 24 * 1000));
        differ -= day * 60 * 60 * 24 * 1000;

        let hour = Number.parseInt(differ / (60 * 60 * 1000));
        differ -= hour * 60 * 60 * 1000;

        let minute = Number.parseInt(differ / (60 * 1000));
        differ -= minute * 60 * 1000;

        let second = Number.parseInt(differ / 1000);

        this.countdown.day = day;
        this.countdown.hour = hour;
        this.countdown.minute = minute;
        this.countdown.second = second;
      } else {
        this.countdown.second -= 1;
      }

      this.isCountdownReady = true;

      setTimeout(this.startTimer, 1000);
    },

    logoutDo() {
      this.$store.commit('site/showProfileModal', false);
      this.$auth.logout();
      this.$router.push('/');
      // location.reload();
    },

    // search
    clearSearchBar() {
      this.searchValue = '';
      this.isSearchBarOpen = false;
      this.$refs.searchBar.blur();
      this.$store.commit('site/showSearchModal', false);
    },

    onSearchBarFocus() {
      this.isSearchBarOpen = true;
    },

    onSearchBarBlur() {
      if (!this.searchValue) {
        this.isSearchBarOpen = false;
      }
    },

    onSearchBarSubmit() {
      // this.$router.push(`/search/${this.searchValue}`);
      this.$router.push(`/search?query=${this.searchValue}`);
      this.clearSearchBar();
      this.$store.commit('site/showSearchModal', false);
    },
  },
};
</script>

<style module>
.is-push-down {
  padding-top: var(--header-height);
}
</style>
